import React, { useState, useEffect } from 'react';
import './App.css';
import kitten1 from './img/kitten1.png';
import kitten2 from './img/kitten2.png';
import honkSound from './assets/honk.mp3';

function App() {
  const [isKitten1, setIsKitten1] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const [countryClicks, setCountryClicks] = useState({});
  const [userCountry, setUserCountry] = useState('Unknown');
  const [localClickCount, setLocalClickCount] = useState(0);
  const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);
  const honkAudio = new Audio(honkSound);

  // Fetch the user's country
  useEffect(() => {
    async function fetchCountry() {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setUserCountry(data.country);
      } catch (error) {
        console.error('Failed to fetch country:', error);
      }
    }
    fetchCountry();
  }, []);

  // Set an interval to periodically update the click counts
  useEffect(() => {
    fetchClickCounts(); // Fetch initially
    const interval = setInterval(fetchClickCounts, 2000); // Update every 2 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Batch updates for local clicks every 12 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (localClickCount > 0) {
        updateCountryClicks(localClickCount);
        setLocalClickCount(0);
      }
    }, 2000); // Update every 2 seconds

    return () => clearInterval(interval);
  }, [localClickCount]);

  const popImage = () => {
    setIsKitten1(false);
    setClickCount((prevCount) => prevCount + 1);
    setLocalClickCount((prevCount) => prevCount + 1);
    honkAudio.currentTime = 0; // Rewind the audio to the start
    honkAudio.play(); // Play the honk sound
  };

  const resetImage = () => {
    setIsKitten1(true);
  };

  const updateCountryClicks = async (clicksToAdd) => {
    try {
      const formData = new FormData();
      formData.append('country', userCountry);
      formData.append('clicks', clicksToAdd);

      const response = await fetch('https://honkcat.net/update_click.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Server response:', data);

        // Add the local clicks to the existing count in state
        setCountryClicks((prev) => ({
          ...prev,
          [data.country]: (prev[data.country] || 0) + clicksToAdd,
        }));
      } else {
        console.error('Failed to update country clicks:', response.statusText);
      }
    } catch (error) {
      console.error('Failed to update country clicks:', error);
    }
  };

  const fetchClickCounts = async () => {
    try {
      const response = await fetch('https://honkcat.net/get_clicks.php');
      if (response.ok) {
        const text = await response.text();
        try {
          const data = JSON.parse(text);
        
          const filteredData = Object.fromEntries(
            Object.entries(data).filter(([country]) => country && country !== 'Unknown')
          );
          setCountryClicks(filteredData);
        } catch (jsonError) {
          console.error('Failed to parse JSON response:', jsonError);
          console.error('Response text:', text);
        }
      } else {
        console.error('Failed to fetch click counts:', response.statusText);
      }
    } catch (error) {
      console.error('Failed to fetch click counts:', error);
    }
  };
  // const mockCountryClicks = {
  //   US: 123,
  //   CA: 98,
  //   FR: 87,
  //   DE: 77,
  //   GB: 69,
  //   AU: 65,
  //   IT: 61,
  //   ES: 59,
  //   JP: 56,
  //   CN: 54,
  //   IN: 51,
  //   BR: 47,
  //   MX: 45,
  //   RU: 42,
  //   SE: 40,
  //   NO: 38,
  //   NL: 35,
  //   CH: 33,
  //   KR: 30,
  //   ZA: 28,
  // };
  
  
  
  

  return (
    <div>
      {/* Drop-up Leaderboard Button and Content */}
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: '0px 30px 30px 30px',
          borderRadius: '10px 10px 0 0',
          fontSize: '1rem',
          zIndex: 1000,
          transition: 'max-height 0.3s ease',
          maxHeight: isLeaderboardOpen ? '50vh' : '200px',
          overflow: 'hidden',
          width: '90%',
          maxWidth: '500px',
        }}
      >
        <h3
          style={{
            fontFamily: '"Londrina Shadow", sans-serif',
            fontSize: '2rem',
            textAlign: 'center',
          }}
        >
          🏆Leaderboard🏆
        </h3>
        
        <ul
  style={{
    listStyleType: 'none',
    padding: 0,
    overflowY: isLeaderboardOpen ? 'auto' : 'hidden',
    maxHeight: isLeaderboardOpen ? 'calc(30vh - 80px)' : '150px', // Increased height to ensure visibility of at least top 3
  }}
>
  {Object.entries(countryClicks)
    .filter(([country]) => country && country !== 'Unknown')
    .sort((a, b) => b[1] - a[1])
    .slice(0, isLeaderboardOpen ? undefined : 3) // Only show top 3 when minimized
    .map(([country, clicks], index) => (
      <li key={country} style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
        <span style={{ display: 'inline-block', width: '20px', marginRight: '5px' }}>
          {index + 1}.
        </span>
        <img
          src={`https://flagcdn.com/16x12/${country.toLowerCase()}.png`}
          alt={`${country} flag`}
          style={{ marginRight: '10px' }}
        />
        <span style={{ flex: 1, marginRight: '10px' }}>{country}</span>
        <span style={{ marginRight: '10px' }}>{clicks} hks</span>
      </li>
    ))}
</ul>




        <button
          onClick={() => setIsLeaderboardOpen((prev) => !prev)}
          style={{
            marginTop: '10px',
            padding: '1px 2px',
            fontSize: '0.8rem',
            cursor: 'pointer',
            backgroundColor: '#fff',
            color: '#000',
            border: '2px solid #000',
            borderRadius: '5px',
          }}
        >
          {isLeaderboardOpen ? '▼' : '▲'}
        </button>
      </div>
        {/* Links Section */}
        <div
          style={{
            position: 'absolute',
            bottom: window.innerWidth > 600 ? '10px' : '300px',
            left: '10px',
            fontSize: '1.2rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            zIndex: 1200,
          }}
        >
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            <li>
              <button
              onClick={() => window.open('https://x.com/honk_cat', '_blank', 'noopener,noreferrer')}
                style={{
                  border: 'none',
                  fontSize: '2rem',
                  fontFamily: '"Londrina Shadow", sans-serif',
                  backgroundColor: 'transparent',
                  color: '#000',
                }}
              >
                X
              </button>
            </li>
            <li>
              <button
              onClick={() => window.open('https://t.me/+CiL1gLflBuUxMjg0', '_blank', 'noopener,noreferrer')}
                style={{
                  border: 'none',
                  fontSize: '2rem',
                  fontFamily: '"Londrina Shadow", sans-serif',
                  backgroundColor: 'transparent',
                  color: '#000',
                }}
              >
                telegram
              </button>
            </li>
            <li>
              <button
              onClick={() => window.open('https://discord.gg/pzYnuBRg', '_blank', 'noopener,noreferrer')}

                style={{
                  border: 'none',
                  fontSize: '2rem',
                  fontFamily: '"Londrina Shadow", sans-serif',
                  backgroundColor: 'transparent',
                  color: '#000',
                }}
              >
                discord
              </button>
            </li>
          </ul>
        </div>
      {/* Main honking container */}
      <div
        className="App"
        onMouseDown={popImage}
        onMouseUp={resetImage}
        onTouchStart={(e) => {
          e.preventDefault();
          popImage();
        }}
        onTouchEnd={(e) => {
          e.preventDefault();
          resetImage();
        }}
        style={{
          backgroundPosition: 'center calc(-60% + 100vh)',
          height: '100vh',
          margin: 0,
          backgroundImage: `url(${isKitten1 ? kitten1 : kitten2})`,
          backgroundSize: 'cover',
        }}
      >
        <p
          style={{
            position: 'absolute',
            fontSize: window.innerWidth > 600 ? '1.5rem' : '1.1rem',
            // top: '25px',
            left: '5%',
            transform: 'translateX(0)',
            color: 'black',
            top: window.innerWidth > 600 ? '25px' : '25%',
            
            fontWeight: 'bold',
            fontFamily: '"Londrina Shadow", sans-serif',
            maxWidth: '90%',
          }}
        >
         7vFXK7XNxKFyzRLccYATC9PbnjG4PzNGECdrnmQ2pump
        </p>
        <div
          style={{
            position: 'absolute',
            top: '20px',
            right: '45%',
            color: 'black',
            fontSize: '4rem',
            fontWeight: 'bold',
            fontFamily: '"Londrina Shadow", sans-serif',
            textAlign: 'center',
            width: 'auto',
          }}
        >
          Honks: {clickCount}
          <button
  style={{
    position: 'fixed',
    top: '80px',  // Set a fixed value relative to the viewport
    right: window.innerWidth > 600 ? '300px' : '20px',
    color: 'black',
    border: '1px solid black',
    borderRadius: '50%',
    padding: '15px',
    fontSize: '2rem',
    fontWeight: 'bold',
    fontFamily: '"Londrina Shadow", sans-serif',
    cursor: 'pointer',
  }}
  onClick={() => window.open('https://pump.fun/coin/7vFXK7XNxKFyzRLccYATC9PbnjG4PzNGECdrnmQ2pump', '_blank', 'noopener,noreferrer')}
>
  BUY
</button>
        </div>


      </div>
    </div>
  );
}

export default App;
